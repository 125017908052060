<template lang="html">
  <div class="page-fade-in" id="complaints-page">

    <!-- <div class="bg-banner" v-bind:style="{ backgroundImage: 'url(public/images/pages/complaints/banner-3.jpg)' }"></div> -->

    <section class="container purchase-section">
      <b-form @submit.prevent="onSubmit($event.target)">
        <div class="row justify-content-center">
          <div class="col-lg-10 text-justify mb-1 col-titles">
            <div class="mb-1 text-center box-title-g-1">
              <h2 class="title">Linea ética o de denuncia</h2>
            </div>
          </div>

          <div class="col-12 col-xl-10 mt-1 mb-4 col-subtitles">
            <h4 class="sub text-center"><span class="px-2">Complete el formulario</span></h4>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Denunciante / Nombre y apellidos o anonimo:" label-for="i-1">
              <b-form-input
                id="i-1"
                v-model="form.name"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Vinculación del denunciante con la organización:" label-for="i-2">
              <b-form-input
                id="i-2"
                v-model="form.connection_organization"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Grupo de empresas:" label-for="i-3">
              <b-form-input
                id="i-3"
                v-model="form.companies_groups"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Sociedad:" label-for="i-4">
              <b-form-input
                id="i-4"
                v-model="form.society"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Centro de trabajo de los hechos:" label-for="i-5">
              <b-form-input
                id="i-5"
                v-model="form.work_center"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="País:" label-for="i-6">
              <b-form-select v-model="form.country" id="i-6">
                <b-form-select-option :value="null">Selecciona una opción</b-form-select-option>
                <b-form-select-option value="México">México</b-form-select-option>
                <b-form-select-option value="Estados Unidos">Estados Unidos</b-form-select-option>
                <b-form-select-option value="Canada">Canada</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Datos contacto:" label-for="i-7">
              <b-form-textarea
                id="i-7"
                v-model="form.contact_information"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-lg-6 col-xl-5 col-form">
            <b-form-group class="custom-f-group" label="Monto del impacto financiero:" label-for="i-8">


              <currency-input id="i-8" class="form-control"
                    v-model="form.amount" placeholder="$0.00" />
             <!--  <b-form-textarea
                id="i-8"
                v-model="form.amount"
                rows="3"
                max-rows="3"
              ></b-form-textarea> -->
            </b-form-group>
          </div>

          <div class="col-lg-10 col-form">
            <b-form-group class="custom-f-group" label="Descripción detallada de la irregularidad o hechos:" label-for="i-9">
              <b-form-textarea
                id="i-9"
                v-model="form.description"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-lg-10 col-form">
            <b-form-group class="custom-f-group" label="Persona(s) responsable(s) de la irregularidad o del comportamiento:" label-for="i-10">
              <b-form-textarea
                id="i-10"
                v-model="form.person_charge"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-lg-10 col-form">
            <b-form-group class="custom-f-group" label="Momento en el que ocurrió hecho e indicar si en la actualidad sigue sucediendo:" label-for="i-11">
              <b-form-textarea
                id="i-11"
                v-model="form.moment"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-lg-10 col-form">
            <b-form-group class="custom-f-group" label="Otra información:" label-for="i-12">
              <b-form-textarea
                id="i-12"
                v-model="form.other_information"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <!-- <div class="col-lg-10 col-form">
            <b-form-group class="custom-f-group">
              <label class="d-block" label-for="i-13">Adjuntar documentación <small class="f-w-600">(whatsApp, imágenes, archivos, etc.)</small>:</label>
              <b-form-file plain
                id="i-13"
                v-model="form.file"
                :state="Boolean(form.file)"
                placeholder=""
                drop-placeholder=""
              ></b-form-file>
            </b-form-group>
          </div> -->
          <div class="col-lg-10 col-form">
               <b-form-group class="custom-f-group">
              <label class="d-block" label-for="i-13">Adjuntar documentación <small class="f-w-600">(whatsApp, imágenes, archivos, etc.)</small>:</label>
                      <div class="col-sm-9">
                        <div class="fileinput fileinput-new" data-provides="fileinput">
                          <div class="fileinput-preview fileinput-exists thumbnail" style="max-width: 400px; max-height: 300px">
                          </div>
                          <div>
                            <span class="btn btn-white btn-file">
                              <input type="file" accept=".doc,.docx,.pdf,image/*" name="document">
                            </span>
                          </div>
                        </div>
                      </div>
              </b-form-group>
          </div>

          <br><br><br><br>
          <div class="col-lg-10 col-form">
           <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.acepto"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                        required
                      >Acepto el uso y guardado de mis datos personales acorde al aviso de privacidad de Colegio de Abogados Hispanohablantes del Estado de Jalisco A.C. y sus subsidiarias. Asi como que mis datos se usen para el análisis y evaluación de información del que resultaran nuevos productos y sistemas.
            </b-form-checkbox>
          </div>

          <br><br><br><br>
          <div class="col-lg-10 mt-3 text-center">
            <b-button type="submit" class="btn-blue f-w-700 px-5">Enviar denuncia</b-button>
          </div>
        </div>

      </b-form>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
          <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
          {{modal.msg}}
          <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-info" slot="button" v-on:click.prevent="closeModal()">Aceptar</b-button>
          </div>
      </sweet-modal>
      <sweet-modal ref="loading" blocking hide-close-button>
          <img src="public/images/loading.gif" width="150px">
          <p> Enviando información. <br>Esto puede tomar un momento...</p>
      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      paquetes: [
        { name: 'Emprendedor', price: '200' },
        { name: 'Micro', price: '1,499' },
        { name: 'Pequeñas', price: '1,499' },
        { name: 'Medianas', price: '8,999' },
        { name: 'Grandes', price: '17,999' },
        { name: 'Ilimitado', price: '49,999' },
        { name: 'Premium Ilimitado', price: '68,999' },
      ],
      extraPacks: [
        { name: '1 empleado', price: '100' },
        { name: '3 empleado', price: '300' },
        { name: '10 empleado', price: '900' },
        { name: '20 empleado', price: '10,600' },
        { name: '30 empleado', price: '26,000' },
      ],
      paquete: { },

      form: {
        paquete: null,
        extraPack: null,
        email: null
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    selectPack(idx){
      this.form.paquete = idx;
    },

    selectExtraPack(idx){
      if(idx == this.form.extraPack){
        this.form.extraPack = null;
      }else{
        this.form.extraPack = idx;
      }
    },
    closeModal(){
        this.$refs.modal.close();
        location.reload();
    },
    onSubmit(form) {
      //event.preventDefault();
      var data=tools.params(form,this.form);
      this.$refs.loading.open();
        axios.post(tools.url("/api/complaint"), data).then((response)=>{
              this.$refs.loading.close();
              this.modal.msg = 'Información guardada correctamente';
              this.modal.icon = 'success';
              this.modal.block = null;
              this.$refs.modal.open();
        }).catch( (error)=> {
              this.$refs.loading.close();
              this.modal.msg = 'Ocurrrio un error al guardar';
              this.modal.icon = 'error';
              this.modal.block = null;
              this.$refs.modal.open();

        });
    },
  },
}
</script>
