<template lang="html">
  <div class="form">
    <h1 class="mb-2">Solicitud de afiliación de asociado</h1>

    <h5 class="mt-3 mb-2 txt-purple text-center f-w-700">REQUISITOS DE INGRESO</h5>

    <div class="d-block px-lg-4">
      <ol class="list-inline">
        <li>1. Asociados Fundadores: consultar estatutos constitutivos.</li>
        <li>2. Asociados Activos:</li>
        <li>2.1. Ser mexicano por nacimiento o naturalización.</li>
        <li>2.2. Haber llenado la solicitud proporcionada por parte de CAHEJ aprobada por la Comisión de Membresía</li>
        <li>2.3. Currículum vitae de forma impresa.</li>
        <li>2.4. Cédula Profesional expedida por la Dirección de Profesiones del Estado de Jalisco, copia.</li>
        <li>2.5. Identificación oficial, copia.</li>
        <li>2.6. Pago de la anualidad.</li>
        <li class="mt-2">3. Asociados Adherentes (honorarios y cooperadores):</li>
        <li>3.1. Ser mexicano por nacimiento o naturalización.</li>
        <li>3.2. Tener una licenciatura, especialidad o posgrado afín al Derecho tributario, penal tributario y/o Administrativo.</li>
        <li>3.3. Haber llenado la solicitud proporcionada por parte de CAHEJ, aprobada por la Comisión de Membresía.</li>
        <li>3.4. Currículum vitae de forma impresa.</li>
        <li>3.5. Cédula Profesional del Estado o en su defecto Federal, copia.</li>
        <li>3.6. Identificación Oficia, copia.</li>
        <li>3.7. Pago de la anualidad.</li>
        <li class="mt-2">4. Pasantes (a y b) y Estudiantes:</li>
        <li>4.1. Ser mexicano por nacimiento o naturalización.</li>
        <li>4.2. Tener carta de pasante de la carrera de abogado o licenciado en derecho expedido por alguna Institución de educación superior legalmente reconocida, o bien acreditar haber cursado cuando menos el 50 % de dicha carrera, copia.</li>
        <li>4.3. Haber llenado la solicitud proporcionada por parte de CAHEJ y que esta haya sido aprobada por la Comisión de Membresía.</li>
        <li>4.4. Currículum vitae de forma impresa con fotografía.</li>
        <li>4.5. Identificación oficial, copia.</li>
        <li>4.6. Kardex del último semestre o cuatrimestre cursado, sólo si es requerido de forma expresa por el CAHEJ.</li>
        <li>4.7. Pago de la anualidad.</li>
      </ol>

      <div class="table-responsive">
        <table class="table table-sm members-table">
          <thead>
            <tr class="title-row">
              <th colspan="6">MEMBRESÍA ANUAL 2022<sup>1</sup></th>
            </tr>
            <tr>
              <th>TIPO</th>
              <th>UMAS</th>
              <th>PESOS</th>
              <th>10% DESC.<br />ENERO</th>
              <th>5% DESC.<br />FEBRERO</th>
              <th>4 PAGOS<br />TRIMESTRAL</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Titulado/Pasante C/Foráneo</td>
              <td>$ 83.14</td>
              <td>$ 8,000.00</td>
              <td>$ 7,200.00</td>
              <td>$ 7,600.00</td>
              <td>$ 2,000.00</td>
            </tr>
            <tr>
              <td>Pasante B</td>
              <td>$ 64.44</td>
              <td>$ 6,200.00</td>
              <td>$ 5,580.00</td>
              <td>$ 5,890.00</td>
              <td>$ 1,550.00</td>
            </tr>
            <tr>
              <td>Pasante A</td>
              <td>$ 54.46</td>
              <td>$ 5,240.00</td>
              <td>$ 4,716.00</td>
              <td>$ 4,978.00</td>
              <td>$ 1,310.00</td>
            </tr>
            <tr>
              <td>Vitalicios 50%</td>
              <td>$ 40.00</td>
              <td>$ 3,848.80</td>
              <td>$ 3,463.92</td>
              <td>$ 3,656.36</td>
              <td>$ 962.20</td>
            </tr>
            <tr>
              <td>Maestros de Tiempo Parcial</td>
              <td>$ 41.57</td>
              <td>$ 4,000.00</td>
              <td>$ 3,600.00</td>
              <td>$ 3,800.00</td>
              <td>$ 1,000.00</td>
            </tr>
            <tr>
              <td>Maestros de Tiempo Completo</td>
              <td>$ 20.79</td>
              <td>$ 2,000.00</td>
              <td>$ 1,800.00</td>
              <td>$ 1,900.00</td>
              <td>$ 500.00</td>
            </tr>
            <tr>
              <td>Estudiantes 100%</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Cooperadores/Colaboradores</td>
              <td>$ 49.89</td>
              <td>$ 4,800.00</td>
              <td>$ 4,320.00</td>
              <td>$ 4,560.00</td>
              <td>$ 1,200.00</td>
            </tr>
            <tr>
              <td>UMA 2022</td>
              <td>$ 96.22</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            
          </tbody>
        </table>
         <td>El costo de la membresía se actualizará de forma anual de conformidad al valor de la UMA vigente para el ejercicio de pago referido.</td>
      </div>

      <div class="d-block text-center my-4">
        <b-button type="button" class="px-4 btn-blue f-w-600" @click="accept()">
          <i class="fas fa-check-square"></i> CUMPLO CON LOS REQUISITOS Y DESEO AFILIARME
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    accept(){
      this.$parent.reqChecked = true;

      window.scrollTo(0, 0);
    }
  }
}
</script>
