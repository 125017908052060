<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(banner,indx) in banners.pc" :key="indx" v-if="device == 0">
          <img class="" :src="banner.imagen">
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(banner,indx) in banners.movil" :key="indx" v-if="device == 1">
          <img class="" :src="banner.imagen">
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </section>

    <section class="about-us-section">
      <div class="container oversized-container">
        <div class="box-content">
          <div class="row align-items-center">
            <div class="col-lg-9 col-info">
              <h5 class="title">SOBRE NOSOTROS</h5>

              <p>
                SOMOS UNA ORGANIZACIÓN NO GUBERNAMENTAL PARA ABOGADOS HISPANOHABLANTES, DEDICADA A APOYAR, CONECTAR, CERTIFICAR AL PROFESIONISTA DEL SECTOR PÚBLICO Y PRIVADO Y, COMUNICAR A LA COMUNIDAD DE ABOGADOS QUE DESEEN EXPANDIR SUS LÍMITES Y PARTICIPAR DE FORMA ACTIVA EN CONGRESOS, CURSOS Y CAPACITACIONES.
              </p>
            </div>

            <div class="col-lg-3 col-image">
              <img class="img-fluid" src="public/images/iso.jpg">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-titles">
            <h5 class="title">Comunicados</h5>
            <p>
              <router-link to="/comunicados">Ver todo</router-link>
            </p>
          </div>

          <div class="col-12 col-carousel">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(blog, bInx) in news" :key="'bInx'+bInx">
                <div class="col-12 px-0 col-article-sample-1">
                  <router-link class="box-article" :to="'/comunicados/'+blog.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
                      <img src="public/images/pages/blog/blog.png">
                    </div>

                    <div class="box-descr">
                      <h5>{{blog.title}}</h5>

                      <div class="descr" v-html="blog.body">

                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-titles">
            <h5 class="title">Blog</h5>
            <p>
              <router-link to="/blog">Ver todo</router-link>
            </p>
          </div>

          <div class="col-12 col-carousel">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(blog, bInx) in blogs" :key="'bInx'+bInx">
                <div class="col-12 px-0 col-article-sample-1">
                  <router-link class="box-article" :to="'/blog/'+blog.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
                      <img src="public/images/pages/blog/blog.png">
                    </div>

                    <div class="box-descr">
                      <h5>{{blog.title}}</h5>

                      <div class="descr" v-html="blog.body">

                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-titles">
            <h5 class="title">Cursos</h5>
            <p>
              <router-link to="/cursos">Ver todo</router-link>
            </p>
          </div>

          <div class="col-12 col-carousel">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(course, bInx) in courses" :key="'bInx'+bInx">
                <div class="col-12 px-0 col-article-sample-1">
                  <router-link class="box-article" :to="'/cursos/'+course.id">
                    <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+course.imageUrl+')' }">
                      <img src="public/images/pages/blog/blog.png">
                    </div>

                    <div class="box-descr">
                      <h5>{{course.title}}</h5>

                      <div class="descr" v-html="course.description">

                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  data(){
    return{
      device:0,
      news:[],
      blogs:[],
      banners:[],
      courses:[],

      // == ==
      bannersOptions: {
        loop: true,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      blogOptions: {
        slidesPerView: 4,
        spaceBetween: 0,
        loop: false,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          576: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },
      // == ==
    }
  },

  methods: {

    getNews(){
      axios.get(tools.url("/api/blogs")).then((response)=>{
          this.news = response.data.news;
          this.blogs = response.data.blogs;
        }).catch((error)=>{
        });
    },
    getCourses(){
      axios.get(tools.url("/api/courses")).then((response)=>{
          this.courses = response.data;

        }).catch((error)=>{
        });
    },
    getBanners(){
        axios.get(tools.url("/api/banners")).then((response)=>{
          this.banners = response.data;

        }).catch((error)=>{
        });
    }


  },
  mounted(){
      var navegador=navigator.userAgent; //busco el "userAgent" del usuario.
      //lista de palabras del "userAgent" en los móviles
      var moviles=["Mobile","iPhone","iPod","BlackBerry","Opera Mini","Sony","MOT","Nokia","samsung"];
      var device=0; //Variable que detectará si se usa un móvil
      for (var i in moviles) { //comprobar en la lista ...
            //si el método "indexOf" no devuelve -1, indica que la palabra está en el "userAgent"
            var compruebo=navegador.indexOf(moviles[i]);
            if (compruebo>-1) {
                device=1; //Si es un móvil, cambio el valor del detector
            }
      }
      this.device = device;
      this.getNews();
      this.getBanners();
      this.getCourses();
  }
}
</script>
