<template lang="html">
  <div id="services-page">

    <section class="info-section">
      <div class="placed-backg image-lg"></div>

      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-info">
            <h1>Nuestros servicios</h1>
           <ul>
              <li>Conferencias</li>
              <li>Cursos y diplomados con alto valor curricular</li>
              <li>Certificación profesional</li>
              <li>Tutoría jurídica</li>
              <li>Representación ante distintos organismos</li>
            
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="services-section">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-lg-8 col-info">
            <p class="mb-3 mb-md-4 text-center">
              <img class="img-fluid logo" src="public/images/iso-transparent.png">
            </p>

            <h2>Algunos de nuestros servicios</h2>
          </div>

          <div class="col-lg-5 text-center col-info">
            <p><span>Incididunt ut labore et dolore magna aliqua uis aute ifces.</span></p>
            <p><span>Incididunt ut labore et dolore magna aliqua labore.</span></p>
            <p><span>Incididunt ut labore et dolore magna aliqua.</span></p>
          </div>

          <div class="col-lg-5 text-center col-info">
            <p><span>Incididunt ut labore et dolore magna aliqua dolore.</span></p>
            <p><span>Incididunt ut labore et dolore magna aliqua et.</span></p>
            <p><span>Incididunt ut labore et dolore magna aliqua officia.</span></p>
          </div>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
export default {
}
</script>
