<template lang="html">
  <footer id="footer">
  	<div class="container oversized-container">
  		<div class="row align-lg-items-center">
  			<div class="col-sm-6 col-lg-3 col-footer">
  				<h6>INFORMACION</h6>

					<!-- <p class="mb-2">
						<router-link class="tt-upp" to="">Preguntas frecuentes</router-link>
					</p> -->
					<p class="mb-2">
						<router-link class="tt-upp" to="">Políticas empresariales</router-link>
					</p>
					<p class="mb-2">
						<router-link class="tt-upp" to="">Términos & condiciones</router-link>
					</p>
					<p>
						<router-link class="tt-upp" to="">Aviso de privacidad</router-link>
					</p>
  			</div>

				<div class="col-sm-6 col-lg-3 col-footer">
  				<h6>MAPA DEL SITIO</h6>

					<p class="mb-2">
						<router-link class="tt-upp" to="/nosotros">Nosotros</router-link>
					</p>
					<!-- <p class="mb-2">
						<router-link class="tt-upp" to="">Directorio</router-link>
					</p> -->
					<p class="mb-2">
						<router-link class="tt-upp" to="/comunicados">Comunicados</router-link>
					</p>
					<p>
						<router-link class="tt-upp" to="/servicios">Servicios</router-link>
					</p>
  			</div>

				<div class="col-lg-4 ml-auto col-footer col-info">
					<p class="mb-2">
						<a class="t-250 btn-circle" href="mailto:membresia@cahej.org"><i class="far fa-envelope"></i></a>
						<a class="t-250 btn-circle" href="https://web.facebook.com/Colegio-de-Abogados-Hispanohablantes-del-Estado-de-Jalisco-AC-105317778720828" target="_blank"><i class="fab fa-facebook-f"></i></a>
					</p>

					<p class="mb-3">
						C. DIAMANTE 2586, INT. 04 y 05, Bosques de La Victoria, <br>44540 Guadalajara, Jal.<br>
					</p>

					<!-- <p class="mb-3">
						Tel. (33)-333-3333
					</p>
 -->
					<p>
						Derechos Reservados  © CAH
					</p>
					<p>Registrado y autorizado por la Dirección de Profesiones del Estado, oficio DP-539/2022.</p>
				</div>
  		</div>
  	</div>
  </footer>
</template>

<script>
export default {
}
</script>
