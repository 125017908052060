import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/nosotros', component: require(page+'nosotros/index.vue').default, meta:{title:"Nosotros"} },
	    { path: '/servicios', component: require(page+'servicios/index.vue').default, meta:{title:"Servicios"} },
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},
      { path: '/canal-de-denuncias', component: require(page+'denuncias/index.vue').default, meta:{title:"Canal de denuncias"}},
      { path: '/canal-de-denuncias/linea-etica-o-de-denuncia', component: require(page+'denuncias/linea-etica-o-de-denuncia.vue').default, meta:{title:"Linea ética o de denuncia"}},

      { path: '/comunicados', name: 'comuPage', component: require(page+'comunicados/index.vue').default, meta:{title:"Comunicados"}},
      { path: '/comunicados/:id?', component: require(page+'comunicados/detail.vue').default, meta:{title:"Comunicados"}},
      { path: '/cursos', name: 'cursosPage', component: require(page+'cursos/index.vue').default, meta:{title:"Cursos"}},
      { path: '/cursos/:id?', component: require(page+'cursos/detail.vue').default, meta:{title:"Cursos"}},
      { path: '/blog', name: 'blogPage', component: require(page+'blog/index.vue').default, meta:{title:"Blog"}},
      { path: '/blog/:id?', component: require(page+'blog/detail.vue').default, meta:{title:"Blog"}},
      
      { path: '/afiliacion', component: require(page+'user-access/register.vue').default, meta:{title:"Afiliación"}},
	 
	{ path: '/certificado/:digital_folio?/:studentsdocument_id?', component: require(page+'certificados/index.vue').default, meta:{title:"Certificados"}},
	{ path: '/profile/:students_id?', component: require(page+'certificados/all.vue').default, meta:{title:"Certificados"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Abogados Hispanohablantes", filter: (title)=>{ return title+" - Abogados Hispanohablantes"; } }
);

// export {routes};
export default MyRouter;
