<template lang="html">
	<div id="certificate-page">

		<section class="main-section">
			<div v-if="validate == true" >
				<div class="box-pdf">
					<div class="container">
						<iframe :src="document_url" style="text-align:center;"></iframe>
					</div>
				</div>

				<div class="container box-info">
					<div class="row">
						<div class="col-lg-8 col-info">
							<h3 class="mb-2 title">{{certificate.title}}</h3>

							<div class="row">
								<div class="col-md-6">
									<h3 class="name">{{student.name}}</h3>
									<router-link class="" :to="'/profile/'+$root._converToURL(student.name,student.id)">Ver todas las credenciales</router-link>
								</div>

								<div class="col-md-6 box-emisor">
									<div class="col col-logo">
										<div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/iso-transparent.png)' }"></div>
									</div>

									<div class="col col-text">
										<h3 class="name">CAHEJ <i class="fas fa-badge-check txt-green"></i></h3>
										<a target="_blank" href="https://cahej.org/">Sitio web del emisor</a>
									</div>
								</div>
							</div>

							<hr />

							<div class="row">
								<div class="col-md-6">
									<p><strong class="f-w-600">Emitida en:</strong> {{documentdata.created_date}}</p>
								</div>

								<div class="col-md-6">
									<p><strong class="f-w-600">Caduca el:</strong> {{documentdata.expired}}</p>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sidebar">
							<b-button class="w-100 btn-blue f-w-500" @click="verificate">Verficar</b-button>

							<div class="pt-3 d-block">
								<p><strong class="f-w-600">Folio digital:</strong> {{documentdata.digital_folio}}</p>
								<p><strong class="f-w-600">Estado:</strong> {{documentdata.state}}</p>
								<p><strong class="f-w-600">Ciudad:</strong> {{documentdata.town}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row flex-center-xy mx-0 px-3 py-4 text-center error-page" v-else-if="validate == false">
				<div class="col-12 px-0 _box">
					<!-- <img src="public/images/pages/certificates/times.png" width="140"><br> -->
					<div class="box-icon-css error">
						<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
			        <circle class="checkmark_circle" cx="26" cy="26" r="25" fill="none" />
			        <path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
						</svg>
					</div>

					<h2 class="mt-2 text-center f-w-600">El certificado es invalido</h2>
				</div>
			</div>
		</section>

		<!-- Modal -->
		<sweet-modal class="modal-certificate" ref="modalAlert" @close="closingModal">
			<div class="box-loading" v-if="showModalContent == false">
				<img src="public/images/loading.gif" alt="Cargando">
				<h6>Verificando el certificado</h6>
			</div>

			<div class="box-content" v-if="validate == true && showModalContent == true" >
				<div class="box-icon-css correct">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
		        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
		        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
			    </svg>
				</div>
				<!-- <div class="box-icon">
					<img class="icon" src="public/images/pages/certificates/check.png">
				</div> -->
				<h3 class="cert-text">El <strong>{{certificate.title}}</strong> fue verificado correctamente.</h3>

				<hr class="my-4">

				<h6 class="mt-4 mb-2 text-left"><i class="fas fa-badge-check txt-green"></i> El propietario de éste certificado está verificado</h6>
				<div class="box-extra-info">
					<p><i class="icon-modal-s1 fas fa-user"></i> Propietario: <b>{{student.name}}</b></p>
					<p><i class="icon-modal-s1 fas fa-file-certificate"></i> Certificado: <b>{{certificate.title}}</b></p>
					<p><i class="icon-modal-s1 fas fa-lock-alt"></i> Folio digital: <b>{{documentdata.digital_folio}}</b></p>
				</div>

				<hr class="my-4">

				<h6 class="mt-4 mb-2 text-left"><i class="fas fa-badge-check txt-green"></i> El emisor está verificado</h6>
				<div class="col-12 px-0 box-emisor">
					<div class="col col-logo">
						<div class="circle" v-bind:style="{ backgroundImage: 'url(public/images/iso-transparent.png)' }"></div>
					</div>

					<div class="col col-text">
						<h3 class="name">CAHEJ</h3>
						<a target="_blank" href="https://cahej.org/">Sitio web del emisor</a>
					</div>
				</div>

				<hr class="my-4">

				<div class="row mx-0 text-left">
					<div class="col-md-6">
						<p><strong class="f-w-600">Emitida en:</strong> {{documentdata.created_date}}</p>
					</div>

					<div class="col-md-6">
						<p><strong class="f-w-600">Caduca el:</strong> {{documentdata.expired}}</p>
					</div>
				</div>
			</div>

			<div class="box-content" v-else-if="validate == false && showModalContent == true">
				<div class="box-icon-css error">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
		        <circle class="checkmark_circle" cx="26" cy="26" r="25" fill="none" />
		        <path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
					</svg>
				</div>
				<!-- <div class="box-icon">
					<img class="icon" src="public/images/pages/certificates/times.png"><br>
				</div> -->

				<h4 class="mt-2 text-center f-w-600">El certificado es invalido</h4>
			</div>
 	 	</sweet-modal>
		<!--  -->

	</div>
</template>

<script>
export default {
  data(){
    return{
			showModalContent: false,
   		validate:null,
   		studentsdocument_id:null,
      student:{},
      certificate:{},
      document_url:null,
      documentdata:{}
    }
  },
  methods: {
		closingModal(){
			this.showModalContent = false;
		},

    getRows(){
      	axios.get(tools.url("/api/checkcertificate/"+this.studentsdocument_id)).then((response)=>{
          this.validate = response.data.status;
          this.student = response.data.student;
          this.certificate = response.data.certificate;
          this.document_url = response.data.document_url;
          this.documentdata = response.data.document;
        }).catch((error)=>{
        });
    },

    verificate(){
      this.$refs.modalAlert.open();

			setTimeout(()=>{
				this.showModalContent = true;
			}, 2600);
    }
  },

  mounted(){
    if(this.$route.params.studentsdocument_id){
      this.studentsdocument_id=this.$route.params.studentsdocument_id;
      this.getRows();
    }
  }
}
</script>
