<template lang="html">
  <div class="page-fade-in" id="complaints-page">

    <!-- <div class="bg-banner" v-bind:style="{ backgroundImage: 'url(public/images/pages/complaints/banner-1.jpg)' }"></div> -->

    <section class="container purchase-section">
      <div>
        <div class="row justify-content-center">
          <div class="col-lg-10 text-justify mb-1 col-titles">
            <div class="mb-1 text-center box-title-g-1">
              <h2 class="title">Canal de denuncias</h2>
            </div>

            <p>
              En CAHEJ creemos firmemente que el éxito de nuestra organización se fundamenta en el respeto, la honestidad, el trabajo en equipo, la innovación, la garantía de calidad, el servicio personalizado, la entrega de resultados al cliente y, la ética de todos los Miembros de la Organización. Para ello compartimos una cultura y normas internas comunes y nos comprometemos, ademas, a actuar de acuerdo a nuestro Código de Conducta y Ética.
            </p>
            <p class="mt-2">
              Conscientes de la importancia de preservar estos valores en nuestra labor diaria, el canal de denuncias es una vía de comunicación CONFIDENCIAL Y ANÓNIMA entre los Miembros de la Organización, los Socios de Negocio, las Partes Interesadas, los Trabajadores o Empleados, entre otros, vinculados a CAHEJ , para que puedan informar cuando sospechen o tengan constancia de que se está produciendo alguna actuación contraria a la legislación o nuestro Código de Conducta y Ética.
            </p>
            <p class="mt-2">
              El objetivo del canal de denuncias es la recepción y tratamiento de las denuncias sobre irregularidades o incumplimientos de la normativa o al Código de Conducta y Ética, cometidos por los Miembros de la Organización, los Socios de Negocio, las Partes Interesadas, los Trabajadores o Empleados.
            </p>
          </div>

          <div class="col-lg-12 col-boxes">
            <div class="row">
              <div class="col-6 col-md-4 col-box" data-aos="fade-up">
                <div class="inside">
                  <div class="box-image">
                    <img src="public/images/pages/complaints/ic-1.png">
                  </div>

                  <div class="box-text">
                    <div>
                      <h6>Introducción</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-box" data-aos="fade-up" data-aos-delay="50">
                <div class="inside">
                  <div class="box-image">
                    <img src="public/images/pages/complaints/ic-2.png">
                  </div>

                  <div class="box-text">
                    <div>
                      <h6>Integridad</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-box" data-aos="fade-up" data-aos-delay="100">
                <div class="inside">
                  <div class="box-image">
                    <img src="public/images/pages/complaints/ic-3.png">
                  </div>

                  <div class="box-text">
                    <div>
                      <h6>Política y procedimiento de cumplimiento</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-box" data-aos="fade-up" data-aos-delay="150">
                <router-link class="inside" to="/canal-de-denuncias/linea-etica-o-de-denuncia">
                  <div class="box-image">
                    <img src="public/images/pages/complaints/ic-4.png">
                  </div>

                  <div class="box-text">
                    <div>
                      <h6 class="blue">Linea ética o de denuncia</h6>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="col-6 col-md-4 col-box" data-aos="fade-up" data-aos-delay="200">
                <div class="inside">
                  <div class="box-image">
                    <img src="public/images/pages/complaints/ic-5.png">
                  </div>

                  <div class="box-text">
                    <div>
                      <h6>Comunicación, entrenamiento y capacitación</h6>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-12 text-center">
            <router-link to="/canal-de-denuncias/linea-etica-o-de-denuncia" class="btn btn-blue f-w-700 px-5 py-2 btn-block">REALIZAR UNA DENUNCIA</router-link>
          </div>

        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{ }
  }
}
</script>
