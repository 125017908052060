<template lang="html">
  <div id="article-page">

    <section class="container content-section">
      <div class="row">
        <!-- <div class="col-12 col-page-title-t1">
          <h1 class="text-center title">Fiscal</h1>
        </div> -->

        <!-- Artículo actual -->
        <div class="col-12 col-article">
          <img class="float-image" :src="detail.imageUrl">
          <!-- <div class="box-image">
          </div> -->
          <h1>{{detail.title}}</h1>

          <div class="descr float" v-html="detail.description">
          </div>

          <div class="descr">
          	<p v-if="detail.date_course">Fecha: {{detail.date_course}}</p>
            <p v-if="detail.duration_hours">Duración en horas: {{detail.duration_hours}}</p>
          	<p v-if="detail.time_start">Desde: {{detail.time_start}}</p>
          	<p v-if="detail.time_end">Hasta: {{detail.time_end}}</p>
            <p v-if="detail.place">Tipo de curso: {{detail.face_virtual_name}}</p>
          	<p v-if="detail.place">Lugar: {{detail.place}}</p>
          	<p v-if="detail.ability">Cupo: {{detail.ability}}</p>
            <p v-if="detail.recognition">Reconocimiento obtenido: {{detail.recognition}}</p>
          	<a v-if="detail.pdfDocument" class="d-inline-block mt-2 f-w-600" :href="detail.pdfDocument" target="_blank"><i class="fas fa-file-pdf"></i> Documento</a>

          	</div>
          	<br><br>
          	<div style="text-align: center;" v-if="id && !showform">
	        	<button class="btn btn-purple px-5 py-2 f-w-600 btn-block" @click="showform = true">SUSCRIBETE</button>
	        </div>
	        <div v-if="showform">
	        	<b-form class="form" @submit.prevent="suscribe" >
	        		<div class="row">
		        		<b-form-group class="col-sm-6  custom-f-group">
			            	<b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
			                <label>Nombre(s)</label>
			            </b-form-group>
			            <b-form-group class="col-sm-6  custom-f-group">
			            	<b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
			                <label>Email</label>
			            </b-form-group>

			            <b-form-group class="col-sm-6  custom-f-group">
			            	<b-form-input type="text" v-model="form.phone" required placeholder=""  maxlength="10"  minlength="10"></b-form-input>
			                <label>Teléfono</label>
			            </b-form-group>

                  <b-form-group class="col-sm-6 custom-f-group">
                    <b-form-select required v-model="form.gender">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option value="woman">Mujer</b-form-select-option>
                      <b-form-select-option value="man">Hombre</b-form-select-option>
                      <b-form-select-option value="other">Otro</b-form-select-option>
                  </b-form-select>

                  <label>Género</label>
                </b-form-group>

			            <b-form-group class="col-sm-6  custom-f-group">
			            	<b-form-input type="text" v-model="form.company" placeholder=""></b-form-input>
			                <label>Empresa, Despacho, Dependencia u Organizacion donde laboro</label>
			            </b-form-group>

                   <b-form-group class="col-sm-6  custom-f-group">
                    <!-- <b-form-input type="text" v-model="form.country" placeholder=""></b-form-input>
                      <label>País</label> -->
                      <b-form-select required v-model="form.country">
                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                        <b-form-select-option value="mexico" >México</b-form-select-option>
                         <b-form-select-option value="otro" >Otro</b-form-select-option>
                    </b-form-select>
                    <label>País</label>
                  </b-form-group>

                  <b-form-group class="col-sm-6  custom-f-group" v-if="form.mexico_country == false">
                    <b-form-input type="text" v-model="form.state" placeholder=""></b-form-input>
                      <label>Estado</label>
                  </b-form-group>
                  <b-form-group class="col-sm-6 custom-f-group" v-else>
                    <b-form-select required v-model="form.state">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option :value="state.id" v-for="(state,indx) in statesOpcs" :key="indx">{{state.name}}</b-form-select-option>
                    </b-form-select>
                    <label>Estado</label>
                  </b-form-group>

                  <b-form-group class="col-sm-6  custom-f-group" v-if="form.mexico_country == false">
                    <b-form-input type="text" v-model="form.town" placeholder=""></b-form-input>
                      <label>Ciudad</label>
                  </b-form-group>

                  <b-form-group class="col-sm-6 custom-f-group" v-else>
                    <b-form-select required v-model="form.town">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option :value="town.id" v-for="(town,indx) in townsOpcs" :key="indx">{{town.name}}</b-form-select-option>
                    </b-form-select>
                    <label>Ciudad</label>
                  </b-form-group>


                  <b-form-group class="col-sm-6 custom-f-group">
                    <b-form-select required v-model="form.profession">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option :value="pro.id" v-for="(pro,indx) in professionOpcs" :key="indx">{{pro.name}}</b-form-select-option>
                  </b-form-select>

                  <label>¿Cual es tu formacion profesional?</label>
                </b-form-group>

                <b-form-group class="col-sm-6 custom-f-group">
                    <b-form-select required v-model="form.self_as">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option :value="self.id" v-for="(self,indx) in self_asOpcs" :key="indx">{{self.name}}</b-form-select-option>
                  </b-form-select>

                  <label>Podria describirme como</label>
                </b-form-group>


			            <b-form-group class="col-sm-6  custom-f-group">
			           		<b-form-checkbox  v-model="form.member_cahej" name="usertos" :value="1" :unchecked-value="0" >
			                	<span class="f-w-500 txt-black">Soy miembro de CAHEJ</span>
			            	</b-form-checkbox>
			            </b-form-group>
			             <b-form-group class="col-sm-6  custom-f-group">
                    <b-form-checkbox  v-model="form.no_member_cahej" name="usertos" :value="1" :unchecked-value="0" >
                        <span class="f-w-500 txt-black">Soy miembro de otro colegio u organización</span>
                    </b-form-checkbox>
			             </b-form-group>
                   <b-form-group class="col-sm-6  custom-f-group" v-if="form.no_member_cahej == 1">
                    <b-form-input type="text" v-model="form.member_other" required placeholder="" ></b-form-input>
                      <label>Nombre de colegio u organización</label>
                  </b-form-group>
                   <b-form-group class="col-sm-6  custom-f-group" v-if="form.no_member_cahej == 1">
                  </b-form-group>

			            <div class="d-block mt-4 text-center">
			              <b-form-group>
			                <b-button type="submit" class="btn-purple px-5 py-2 f-w-600 btn-block"><i class="fas fa-file-alt"></i>SUSCRIBETE</b-button>
			              </b-form-group>
			            </div>

			        </div>
	        	</b-form>

	        </div>
        </div>

        <!--  -->

        <!-- Artículos -->
        <!-- <div class="col-lg col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(arti, arInx) in detail.related" :key="'ar-'+arInx">
              <router-link class="box-article" :to="'/cursos/'+arti.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+arti.imageUrl+')' }">

                   <img src="public/images/shared/empty.png">
                </div>

                <div class="box-descr">
                  <h5>{{arti.title}}</h5>

                  <div class="descr" v-html="arti.description">

                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->


        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-info" slot="button" v-on:click.prevent="closeModal()">Aceptar</b-button>
      </div>
    </sweet-modal>
    <sweet-modal ref="loading" blocking hide-close-button>
        <img src="public/images/loading.gif" width="150px">
        <p> Cargando...</p>
    </sweet-modal>
        <!--  -->
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{

      detail:{
      	date_course:null,
      	time_start:null,
      	time_end:null,
      	place:null,
      	pdfDocument:null

      },
      showform:false,
      form:{
      	name:null,
      	email:null,
      	phone:null,
      	member_cahej:null,
        no_member_cahej:null,
      	member_other:null,
        company:null,
        country:null,
        state:null,
        town:null,
        profession:null,
        self_as:null,
        gender:null,

      	courses_id:null,
        mexico_country:false,
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      professionOpcs:[
        {id:1,name:'Lic. en Derecho / Ciencias Jurídicas'},
        {id:2,name:'Otra licenciatura de ciencias sociales'},
        {id:3,name:'Lic. En Contaduria / Ciencias Administrativas'},
        {id:4,name:'No cuento con título profesional'},
        {id:5,name:'Aún me encuentro estudiando mi carrera profesional'},
        {id:6,name:'Otra licenciatura de ciencias exactas / STEM'},
      ],
      self_asOpcs:[
        {id:1,name:'Abogado interno'},
        {id:2,name:'Abogado postulante Independiente'},
        {id:3,name:'Docente / Investigador'},
        {id:4,name:'Empresario / Emprendedor'},
        {id:5,name:'Estudiante'},
        {id:6,name:'Servidor público'},
        {id:7,name:'Activista'},

      ],
       
       statesOpcs:[],
       townsOpcs:[]
    }
  },
  watch:{
    '$route.params.id':function(){
       this.id=this.$route.params.id;
        this.getRows();
    },
    'form.country':function(val){
        if (val != null) {
          if (val == 'mexico' || val == 'Mexico' || val == 'MEXICO' || val == 'méxico' || val == 'México' || val == 'MÉXICO') {
              this.form.mexico_country = true;
          }
          else{
              this.form.mexico_country = false;
          }
        }
    },
    'form.state':function(val){
        if(this.form.mexico_country == true){
            this.getTowns(val);
        }
        
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    getRows(){

      axios.get(tools.url("/api/courses/"+this.id)).then((response)=>{
          this.detail = response.data;

        }).catch((error)=>{
        });
    },
    suscribe(evt){
    	evt.preventDefault();
    	this.form.courses_id = this.id;
    	this.$refs.loading.open();
        axios.post(tools.url("/api/coursessubscribeds"), this.form).then((response)=>{
              this.$refs.loading.close();
              this.modal.msg = 'Gracias por suscribirte pronto te contactaremos';
              this.modal.icon = 'success';
              this.modal.block = null;
              this.$refs.modal.open();
        }).catch( (error)=> {
              this.$refs.loading.close();
              this.modal.msg = 'Ocurrrio un error al guardar';
              this.modal.icon = 'error';
              this.modal.block = null;
              this.$refs.modal.open();

        });
    },
    closeModal(){
        this.$refs.modal.close();
       	location.reload();
    },
    getStates(){
        axios.get(tools.url("/api/states")).then((response)=>{
          this.statesOpcs = response.data;

        }).catch((error)=>{
        });
    },
    getTowns(state){
        axios.get(tools.url("/api/towns/"+state)).then((response)=>{
          this.townsOpcs = response.data;

        }).catch((error)=>{
        });
    }


  },
  mounted(){
      this.getStates();
      if(this.$route.params.id){
        this.id=this.$route.params.id;
        this.getRows();

      }

  }
}
</script>
