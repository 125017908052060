<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.png">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="align-items-center">
              <b-nav-item to="/">Inicio</b-nav-item>
              <b-nav-item to="/nosotros">Nosotros</b-nav-item>
              <!-- <b-nav-item to="/directorio">Directorio</b-nav-item> -->
              <b-nav-item to="/comunicados">Comunicados</b-nav-item>
              <b-nav-item to="/blog">Blog</b-nav-item>
              <b-nav-item to="/cursos">Cursos</b-nav-item>
              <b-nav-item to="/servicios">Servicios</b-nav-item>
              <b-nav-item class="text-center" to="/canal-de-denuncias">Canal de denuncias</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="align-items-center ml-auto">
              <li class="nav-item nav-membership">
                <router-link class="t-250 nav-link" to="/afiliacion">Afíliate</router-link>
              </li>
              <li class="nav-item nav-networks">
                <div class="nav-link">
                  <router-link class="t-250 btn-circle" to="/contacto"><i class="fal fa-envelope"></i></router-link>
                  <a class="t-250 btn-circle" href="#"><i class="fab fa-facebook-f"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
