<template lang="html">
  <div id="article-page">

    <section class="container content-section">
      <div class="row">
        <!-- <div class="col-12 col-page-title-t1">
          <h1 class="text-center title">Fiscal</h1>
        </div> -->

        <!-- Artículo actual -->
        <div class="col-12 col-article">
          <img class="float-image" :src="detail.imageUrl">
          <!-- <div class="box-image">
            <img :src="detail.imageUrl">
          </div> -->
          <h1>{{detail.title}}</h1>


          <div class="descr float" v-html="detail.body">

          </div>
          <p>Fuente:<br>{{detail.font}}</p>

        </div>
        <!--  -->

        <!-- Artículos -->
        <!-- <div class="col-lg col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(arti, arInx) in detail.related" :key="'ar-'+arInx">
              <router-link class="box-article" :to="'/comunicados/'+arti.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+arti.imageUrl+')' }">

                   <img src="public/images/shared/empty.png">
                </div>

                <div class="box-descr">
                  <h5>{{arti.title}}</h5>

                  <div class="descr" v-html="arti.body">

                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
        <!--  -->
      </div>
    </section>
    <section class="container content-section">
        <social-sharing
                      :url="sharing.url+$route.path"
                      :title="sharing.title"
                      :description="detail.title"
                      :quote="detail.title"
                      :hashtags="detail.title"
                      :twitterUser="sharing.twitterUser"
                      inline-template>
                      <div style="font-size: 30px;">
                          <network network="email" style="cursor: pointer;"><i class="fa fa-envelope"></i></network>

                          <network network="facebook" style="cursor: pointer;"><i class="fab fa-facebook-square"></i></network>
                          <network network="twitter" style="cursor: pointer;"><i class="fab fa-twitter-square"></i></network>

                          <network network="whatsapp" style="cursor: pointer;">  <i class="fab fa-whatsapp-square"></i></network>
                      </div>
        </social-sharing>

    </section>

    <section class="container comments-section" id="calification_company">
      <div class="mb-1 box-el-title">
        <h3>Comentarios</h3>
      </div>

      <div class="row">
        <div class="col-xl-6 col-comment" v-for="(calification, indxc) in detail.comments" :key="indxc">
          <div class="content">
            <h6 class="name">{{calification.name}}</h6>
            <h6 class="email">{{calification.email}}</h6>
            <div class="comment-body">
              <p>{{calification.msg}}</p>
            </div>
            <h6 class="date">{{ getDate(calification.created_at) }}</h6>
          </div>
          <br>
        </div>
      </div>
    </section>

    <hr>
    <section class="container comments-form-section" id="form_califications">
      <div class="mb-2 box-el-title">
        <h3>Deja tu comentario</h3>
      </div>

      <div class="row">
        <div class="col-lg-8 col-xl-9 content">
          <b-form class="box-form-comment" @submit="onSubmit">

            <div>
              <b-form-group class="custom-f-group-2">
                <b-form-input
                  v-model="formcoment.name"
                  placeholder="Nombre"
                  rows="4"
                  max-rows="4"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group class="custom-f-group-2">
                <b-form-input
                  v-model="formcoment.email"
                  placeholder="Email"
                  rows="4"
                  max-rows="4"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div>
              <b-form-group class="custom-f-group-2">
                <b-form-textarea
                  v-model="formcoment.msg"
                  placeholder="Comentarios"
                  rows="4"
                  max-rows="4"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="box-form-group-3 text-center text-md-right">
              <b-button type="submit" class="btn-blue f-w-500">Comentar</b-button>
            </div>

          </b-form>
        </div>
      </div>
    </section>
    <sweet-modal :icon="modal.icon" ref="modalAlert">
          {{modal.msg}}
      </sweet-modal>
  </div>
</template>

<script>
export default {
  data(){
    return{

      detail:{
          comments:[],
      },
      formcoment:{},
      comments:[],
      modal:{
          icon:null,
          msg:null
      },
      // == Sharing ==
      sharing: {
        url: 'https://cahej.org/#',
        title: 'Cahej',
        description: 'Descripcion',
        quote: 'x',
        hashtags: 'x',
        twitterUser: 'twitter'
      },
    }
  },
  watch:{
    '$route.params.id':function(){
       this.id=this.$route.params.id;
        this.getNews();
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    getNews(){

      axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
          this.detail = response.data;

        }).catch((error)=>{
        });
    },
    onSubmit(event) {
      event.preventDefault();

        this.formcoment.blogs_id = this.id;
        axios.post(tools.url("/api/blogComments"),this.formcoment).then((response)=>{
            this.formcoment.name = null;
            this.formcoment.email = null;
            this.formcoment.msg = null;

            this.modal.icon = 'success'
            this.modal.msg = 'Comentario guardado con exito';
            this.$refs.modalAlert.open();
             this.getNews();
        }).catch((error)=>{
            this.modal.icon = 'error'
            this.modal.msg = 'Ocurrio un error al guardar';
            this.$refs.modalAlert.open();
        });
    },
    getDate(val){
      const monthsEs = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
      let today = new Date(val);

      return `${today.getDate()}/${monthsEs[today.getMonth()]}/${today.getFullYear()}`;
    }
  },
  
  mounted(){
    if(this.$route.params.id){
      this.id=this.$route.params.id;
      this.getNews();
    }
  }
}
</script>
