<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container" id="maindiv">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container w-100">
          <div class="box-color" ><i><u class="fas fa-user"></u></i></div>

          <requirements-component v-if="!reqChecked"></requirements-component>
          

          <b-form class="form" @submit.prevent="register" v-if="!id && reqChecked">
            <h1 class="mb-2">Solicitud de afiliación de asociado</h1>

            <div class="d-block text-justify mb-3" style="line-height: 1.35;">
              <p>
                Por medio de la presente hago expresar mi deseo de ingresar a la agrupación de profesionistas denominada: COLEGIO DE ABOGADOS
                HISPANOHABLANTES DEL ESTADO DE JALISCO A.C., protestando respetar y cumplir fielmente con los estatus que lo rigen, el Código Conducta,
                el código ética, los acuerdos de la asamblea, así como al consejo directivo, y demás órganos que lo habrán de representar.
              </p>
              <p>
                Para lo anterior proporciono mis datos personales y profesionales:
              </p>
            </div>

            <h5 class="text-center txt-purple f-w-700">I. SOLICITUD DE INGRESO</h5>
            <div class="row">
              <!-- <b-form-group class="col-sm-6 col-lg-3 custom-f-group">
                <b-form-input type="text" v-model="form.member_number" required placeholder=""></b-form-input>
                <label>No. Socio</label>
              </b-form-group> -->

              <b-form-group class="col-sm-6 col-lg custom-f-group">
                <b-form-input type="date" v-model="form.date_elaboration"  placeholder="" required></b-form-input>
                <label>Fecha de elaboración</label>
              </b-form-group>

             <!--  <b-form-group class="col-sm-6 col-lg custom-f-group">
                <b-form-input type="date" v-model="form.date_adminission_graduate"  placeholder=""></b-form-input>
                <label>Fecha de ingreso titulados</label>
              </b-form-group> -->
            </div>

            <!--div class="row">
              <b-form-group class="col-sm-6 col-lg-6 col-xl-3 custom-f-group">
                <b-form-input type="date" v-model="form.date_entry_intern"  placeholder=""></b-form-input>
                <label>Fecha de ingreso pasante</label>
              </b-form-group>

               <b-form-group class="col-sm-6 col-lg-6 col-xl-3 custom-f-group">
                <b-form-input type="date" v-model="form.date_expulsion"  placeholder=""></b-form-input>
                <label>Fecha de expulsión del socio</label>
              </b-form-group>

              <b-form-group class="col-12 col-lg custom-f-group">
                <b-form-input type="text" v-model="form.reason_expulsion"  placeholder=""></b-form-input>
                <label>Motivo de expulsión</label>
              </b-form-group>
            </div-->


            <h6 class="mt-4 text-center txt-purple f-w-700">A. DATOS GENERALES</h6>
            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group" v-if="form.lastname_m != null">
                <b-form-input type="text" v-model="form.lastname_p" placeholder=""></b-form-input>
                <label>Apellido paterno</label>
              </b-form-group>
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group" v-else>
                <b-form-input type="text" v-model="form.lastname_p" required placeholder=""></b-form-input>
                <label>Apellido paterno</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group" v-if="form.lastname_p != null">
                <b-form-input type="text" v-model="form.lastname_m"  placeholder=""></b-form-input>
                <label>Apellido materno</label>
              </b-form-group>
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group" v-else>
                <b-form-input type="text" v-model="form.lastname_m" required placeholder=""></b-form-input>
                <label>Apellido materno</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
                <label>Nombre(s)</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.rfc" required placeholder=""></b-form-input>
                <label>RFC</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.curp" required placeholder=""></b-form-input>
                <label>CURP</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="date" v-model="form.date_birth" required placeholder=""></b-form-input>
                <label>Fecha de nacimiento</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-5 custom-f-group">
                <b-form-input type="text" v-model="form.place_birth" required placeholder=""></b-form-input>
                <label>Lugar de nacimiento</label>
              </b-form-group>
            </div>

            <h6 class="mt-2 text-center txt-gray f-w-700">DOMICILIO PARTICULAR</h6>
            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.street" required placeholder=""></b-form-input>
                <label>Calle</label>
              </b-form-group>

              <b-form-group class="col-6 col-sm-3 col-lg-2 custom-f-group">
                <b-form-input type="text" v-model="form.num_ext" required placeholder=""></b-form-input>
                <label>No. Ext.</label>
              </b-form-group>

              <b-form-group class="col-6 col-sm-3 col-lg-2 custom-f-group">
                <b-form-input type="text" v-model="form.num_int" placeholder=""></b-form-input>
                <label>No. Int.</label>
              </b-form-group>
              <div class="col-lg-4"></div>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.neighborhood" required placeholder=""></b-form-input>
                <label>Colonia</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                 <b-form-select required v-model="form.state_id">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option :value="state.id" v-for="(state,indx) in statesOpcs" :key="indx">{{state.name}}</b-form-select-option>
                </b-form-select>
                <label>Estado</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                 <b-form-select required v-model="form.town_id">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option :value="town.id" v-for="(town,indx) in townsOpcs" :key="indx">{{town.name}}</b-form-select-option>
                </b-form-select>

                <label>Municipio</label>
              </b-form-group>


              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.zipcode" required placeholder=""></b-form-input>
                <label>Código postal</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.email" required placeholder=""></b-form-input>
                <label>Correo electrónico</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.phone" required :minlength="10" :maxlength="10"></b-form-input>
                <label>Teléfono</label>
              </b-form-group>
            </div>

            <!-- II -->
            <h5 class="mt-5 text-center txt-purple f-w-700">II. GRADO ACADÉMICO</h5>
            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.area_praxis" required placeholder=""></b-form-input>
                <label>Área de praxis</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.specialty_a" required placeholder=""></b-form-input>
                <label>Especialidad a)</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.specialty_b" placeholder=""></b-form-input>
                <label>Especialidad b)</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.specialty_c" placeholder=""></b-form-input>
                <label>Especialidad c)</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.specialty_d" placeholder=""></b-form-input>
                <label>Especialidad d)</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-6 custom-f-group">
                <b-form-input type="text" v-model="form.career_completed" required placeholder=""></b-form-input>
                <label>Carrera cursada</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-6 custom-f-group">
                <b-form-input type="text" v-model="form.title_achieved" required placeholder=""></b-form-input>
                <label>Título logrado</label>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-select required v-model="form.university">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option value="udg">U. de G.</b-form-select-option>
                  <b-form-select-option value="iteso">ITESO</b-form-select-option>
                  <b-form-select-option value="up">UP</b-form-select-option>
                  <b-form-select-option value="uag">UAG</b-form-select-option>
                  <b-form-select-option value="itesm">ITESM</b-form-select-option>
                  <b-form-select-option value="other">Otra</b-form-select-option>
                </b-form-select>
                <label>Universidad</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-6 custom-f-group" v-if="form.university == 'other'">
                <b-form-input type="text" v-model="form.university_other" required placeholder=""></b-form-input>
                <label>Nombre de la Universidad</label>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.professional_certificate" required placeholder=""></b-form-input>
                <label>Cédula Prof.</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.state_certificate" required placeholder=""></b-form-input>
                <label>Cédula Estatal</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.provisional_certificate" placeholder=""></b-form-input>
                <label>Cédula provicional</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.imss"  placeholder=""></b-form-input>
                <label>Reg. IMSS</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.infonavit"  placeholder=""></b-form-input>
                <label>Reg. INFONAVIT</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.ine" required placeholder=""></b-form-input>
                <label>No. INE</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.postgraduate" required placeholder=""></b-form-input>
                <label>Posgrado</label>
              </b-form-group>

              <!-- <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.university_postgraduate" required placeholder=""></b-form-input>
                <label>Universidad?</label>
              </b-form-group> -->

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="date" v-model="form.date_end" required placeholder=""></b-form-input>
                <label>Fecha de término</label>
              </b-form-group>
            </div>
            <!-- II - Fin -->

            <!-- III -->
            <h5 class="mt-5 text-center txt-purple f-w-700">III. PRAXIS PROFESIONAL</h5>
            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.workplace" required placeholder=""></b-form-input>
                <label>Centro de trabajo</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.position" required placeholder=""></b-form-input>
                <label>Puesto</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-select required v-model="form.sector">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option value="publico">Público</b-form-select-option>
                  <b-form-select-option value="privado">Privado</b-form-select-option>
                  <b-form-select-option value="docencia">Docencia</b-form-select-option>
                  <b-form-select-option value="despacho">Despacho</b-form-select-option>
                  <b-form-select-option value="otro">Otro</b-form-select-option>
                </b-form-select>
                <label>Sector</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group" v-if="form.sector == 'otro'">
                <b-form-input type="text" v-model="form.sector_other" required placeholder=""></b-form-input>
                <label>Otro</label>
              </b-form-group>
            </div>

            <h6 class="mt-2 text-center txt-gray f-w-700">DOMICILIO DEL TRABAJO</h6>
            <div class="row">
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.street_work" required placeholder=""></b-form-input>
                <label>Calle</label>
              </b-form-group>

              <b-form-group class="col-6 col-sm-3 col-lg-2 custom-f-group">
                <b-form-input type="text" v-model="form.num_ext_work" required placeholder=""></b-form-input>
                <label>No. Ext.</label>
              </b-form-group>

              <b-form-group class="col-6 col-sm-3 col-lg-2 custom-f-group">
                <b-form-input type="text" v-model="form.num_int_work" placeholder=""></b-form-input>
                <label>No. Int.</label>
              </b-form-group>
              <div class="col-lg-4"></div>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.neighborhood_work" required placeholder=""></b-form-input>
                <label>Colonia</label>
              </b-form-group>

               <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                 <b-form-select required v-model="form.state_id_work">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option :value="state.id" v-for="(state,indx) in statesOpcs" :key="indx">{{state.name}}</b-form-select-option>
                </b-form-select>
                <label>Estado</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                 <b-form-select required v-model="form.town_id_work">
                  <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                  <b-form-select-option :value="town.id" v-for="(town,indx) in townsTrabOpcs" :key="indx">{{town.name}}</b-form-select-option>
                </b-form-select>

                <label>Municipio</label>
              </b-form-group>

             
              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.zipcode_work" required placeholder=""></b-form-input>
                <label>Código postal</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.email_work" required placeholder=""></b-form-input>
                <label>Correo electrónico</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.phone_work" required :minlength="10" :maxlength="10"></b-form-input>
                <label>Teléfono</label>
              </b-form-group>
            </div>
            <!-- III - Fin -->

            <!-- IIII -->
            <h5 class="mt-5 text-center txt-purple f-w-700">IV. DATOS DE CONTACTO DE EMERGENCIA</h5>
            <div class="row">
              <b-form-group class="col-md-6 col-lg-5 custom-f-group">
                <b-form-input type="text" v-model="form.contact_emergency" required placeholder=""></b-form-input>
                <label>Nombre del contacto de emergencia</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-4 custom-f-group">
                <b-form-input type="text" v-model="form.relationship" required placeholder=""></b-form-input>
                <label>Parentesco</label>
              </b-form-group>

              <b-form-group class="col-sm-6 col-lg-3 custom-f-group">
                <b-form-input type="text" v-model="form.phone_emergency" required :minlength="10" :maxlength="10"></b-form-input>
                <label>Teléfono</label>
              </b-form-group>

              <!-- <div class="col-12 mt-1">
                <p class="text-center text-md-right text-danger f-w-600"><i class="fas fa-check"></i> Favor de llenar todos los campos</p>
              </div> -->
            </div>
            <!-- IIII - Fin -->


            <div class="d-block mt-4 text-center">
              <b-form-group>
                <b-button type="submit" class="px-4 btn-blue f-w-700"><i class="fas fa-file-alt"></i> SIGUIENTE</b-button>
              </b-form-group>
            </div>
          </b-form>

          <b-form role="form" class="form" @submit.prevent="registerDocs($event.target)" v-else-if="id && reqChecked">
            <h1 class="mb-2">Documentación</h1>
            <div class="col-sm-12 col-form">
                <b-form-group
                    label="Curriculum:"
                    label-for="i-curriculum">
                    <div class="col-12 px-0">
                      <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-preview fileinput-exists thumbnail">
                        </div>
                        <div>
                          <span class="btn btn-white btn-file">
                            <input type="file" accept=".pdf" name="curriculum" id="curriculum">
                          </span>
                        </div>
                      </div>
                    </div>
                </b-form-group>
            </div>

            <div class="col-sm-12 col-form">
                <b-form-group
                    label="Cedula Profesional:"
                    label-for="i-cedula">
                    <div class="col-12 px-0">
                      <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-preview fileinput-exists thumbnail">
                        </div>
                        <div>
                          <span class="btn btn-white btn-file">
                            <input type="file" accept=".pdf" name="professional_certificate" id="professional_certificate">
                          </span>
                        </div>
                      </div>
                    </div>
                </b-form-group>
            </div>

            <div class="col-sm-12 col-form">
                <b-form-group
                    label="Indetificación oficial:"
                    label-for="i-indetification">
                    <div class="col-12 px-0">
                      <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-preview fileinput-exists thumbnail">
                        </div>
                        <div>
                          <span class="btn btn-white btn-file">
                            <input type="file" accept=".pdf" name="indetification" id="indetification">
                          </span>
                        </div>
                      </div>
                    </div>
                </b-form-group>
            </div>

            <div class="col-sm-12 col-form">
                <b-form-group
                    label="Comprobante del pago de la anualidad:"
                    label-for="i-proof_payment">
                    <div class="col-12 px-0">
                      <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-preview fileinput-exists thumbnail">
                        </div>
                        <div>
                          <span class="btn btn-white btn-file">
                            <input type="file" accept=".pdf" name="proof_payment" id="proof_payment">
                          </span>
                        </div>
                      </div>
                    </div>
                </b-form-group>
            </div>
            
            <div class="col-sm-12 col-form">
                <b-form-group
                    label=" Kardex del último semestre cursado:"
                    label-for="i-kardex">
                    <div class="col-12 px-0">
                      <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-preview fileinput-exists thumbnail">
                        </div>
                        <div>
                          <span class="btn btn-white btn-file">
                            <input type="file" accept=".pdf" name="kardex" id="kardex">
                          </span>
                        </div>
                      </div>
                    </div>
                </b-form-group>
            </div>

             <!-- Extras -->
            <hr class="mt-5" />
            <h5 class="text-center txt-purple f-w-700">“PROTESTO DECIR VERDAD”</h5>

            <div class="d-block mt-3 text-center">
              <h6 class="f-w-600 txt-black">Guadalajara, Jalisco, a <u>{{today.getDate()}}</u> de <u class="text-capitalize">{{today.toLocaleString("es-MX", {month: "long"})}}</u> del <u>{{today.getFullYear()}}</u></h6>
            </div>

            <div class="d-block mt-3 text-center">
              <b-form-checkbox v-model="form.tos" name="usertos" value="1" unchecked-value="" required>
                <span class="f-w-500 txt-black">Acepto los términos y condiciones</span>
              </b-form-checkbox>
            </div>
            <!-- Extras - Fin -->

            <!-- <div class="mb-4 form-errors" v-if="formErrors.length">
              <hr />
              <ul class="pl-3">
                <li class="text-danger" v-for="formError in formErrors">
                  <small>{{ formError }}</small>
                </li>
              </ul>
            </div> -->

            <div class="d-block mt-4 text-center">
              <b-form-group>
                <b-button type="submit" class="px-4 btn-blue f-w-700"><i class="fas fa-file-alt"></i> ENVIAR FORMULARIO DE AFILIACIÓN</b-button>
              </b-form-group>
            </div>
          </b-form>

        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-info" slot="button" v-on:click.prevent="closeModal()">Aceptar</b-button>
      </div>
    </sweet-modal>
    <sweet-modal ref="loading" blocking hide-close-button>
        <img src="public/images/loading.gif" width="150px">
        <p> Cargando...</p>
    </sweet-modal>

  </div>
</template>

<script>
import requirementsComponent from './reg-requirements-component.vue';
export default {
  data(){
    return{
      id:null,
      reqChecked: false,
      today: null,
      formErrors: [],

      form:{
        member_number:null,
        //date_elaboration:null,
        date_adminission_graduate:null,
        date_entry_intern:null,
        //date_expulsion:null,
        //reason_expulsion:null,

        lastname_p:null,
        lastname_m:null,
        name:null,
        rfc:null,
        curp:null,
        date_birth:null,
        place_birth:null,
        street:null,
        num_ext:null,
        num_int:null,
        neighborhood:null,
        state_id:null,
        town_id:null,
        zipcode:null,
        email:null,
        phone:null,

        area_praxis:null,
        specialty_a:null,
        specialty_b:null,
        specialty_c:null,
        specialty_d:null,
        career_completed:null,
        title_achieved:null,
        university:null,
        university_other:null,
        professional_certificate:null,
        state_certificate:null,
        provisional_certificate:null,
        imss:null,
        infonavit:null,
        ine:null,
        postgraduate:null,
        //university_postgraduate:null,
        date_end:null,
        
        workplace:null,
        position:null,
        sector:null,
        sector_otro:null,
        street_work:null,
        num_ext_work:null,
        num_int_work:null,
        neighborhood_work:null,
        state_id_work:null,
        town_id_work:null,
        zipcode_work:null,
        email_work:null,
        phone_work:null,
        contact_emergency:null,
        relationship:null,
        phone_emergency:null,
        
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      statesOpcs:[],
      townsOpcs:[],
      townsTrabOpcs:[],
    }
  },
  watch:{
      'form.state_id':function(val){
          this.getTowsOpcs(val);
      },
      'form.state_id_work':function(val){
          this.getTowsTrabOpcs(val);
      },
  },
  methods: {
    register(evt){
         evt.preventDefault();
      var resta;

      var valores = this.form.date_birth.split ("-");
      var dia_naci = valores [2];
      var mes_naci = valores [1];
      var anio_naci = valores [0];

      var fecha_act = new Date();

      var dia_act = fecha_act.getDate();
      var mes_act = fecha_act.getMonth() + 1;
      var anio_act = fecha_act.getFullYear();
        
      // Solo voy a considerar hasta el mes para tomarlo como mayor de edad, tu puedes aumentar la especificación aumentando a la operación la sustracción de la diferencia de días.
      // sí deseas consideras los días en el if vas a tener que ir hasta los días que sería 18*12*365 entonces if ( resta < (18*12*365) ) {...}
      // resta (considerando hasta los meses) = diferencia de años (los meses que ha vivido la persona entonces *12) - (meses que le falta para su mes de nacimiento)
      // resta (considerando hasta los días) = diferencia de años (*12 *365) - diferencia de meses (*30) - diferencia de días.

      resta = (anio_act - anio_naci) * 12 - (mes_act - mes_naci);
      
      if ( resta  <  (18*12) ) {
           alert('Debes de ser mayor de edad');
          return;
      }

      
      

      this.$refs.loading.open();
      // this.formErrors = [];
         
          axios.post(tools.url("/api/saveLawyers"), this.form).then((response)=>{
              this.$refs.loading.close();
              this.id = response.data.id;
              var scrollt = document.getElementById("maindiv").offsetTop;
              setTimeout( ()=>{
                window.scrollTo({ top: scrollt, left: 0, behavior: 'smooth' });
              }, 200);

              /*this.modal.msg = 'Información guardada exitosamente';
              this.modal.icon = 'success';
              this.modal.block = null;
              this.$refs.modal.open();*/
            //this.makeToast('default', response.data.response, 'Mensaje enviado');
            
            
          }).catch( (error)=> {
              this.$refs.loading.close();
              this.modal.msg = 'Ocurrrio un error al guardar';
              this.modal.icon = 'error';
              this.modal.block = null;
              this.$refs.modal.open();
          
        });
    },
    registerDocs(form){
      this.$refs.loading.open();
      //evt.preventDefault();
        var data=tools.params(form,this.form);

          axios.post(tools.url("/api/saveLawyersDocs/"+this.id), data).then((response)=>{
              this.$refs.loading.close();
              this.modal.msg = 'Información guardada exitosamente';
              this.modal.icon = 'success';
              this.modal.block = null;
              this.$refs.modal.open();
              /*setTimeout( ()=>{
                location.reload();
              }, 5000);*/
            //this.makeToast('default', response.data.response, 'Mensaje enviado');
            
          }).catch( (error)=> {
              this.$refs.loading.close();
              this.modal.msg = 'Ocurrrio un error al guardar';
              this.modal.icon = 'error';
              this.modal.block = null;
              this.$refs.modal.open();
          
        });
    },
    getStatesOpcs(){
      axios.get(tools.url("/api/states")).then((response)=>{
          this.statesOpcs=response.data;
        }).catch((error)=>{
        });
    },
    getTowsOpcs(state_id){
      axios.get(tools.url("/api/towns/"+state_id)).then((response)=>{
          this.townsOpcs=response.data;
        }).catch((error)=>{
        });
    },
    getTowsTrabOpcs(state_id){
      axios.get(tools.url("/api/towns/"+state_id)).then((response)=>{
          this.townsTrabOpcs=response.data;
        }).catch((error)=>{
        });
    },
    closeModal(){
        this.$refs.modal.close();
        this.$router.push('/');
    }
  },

  mounted(){
    
    this.today = new Date();
    this.getStatesOpcs();

  },

  components: {
    requirementsComponent
  },
}
</script>
