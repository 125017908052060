<template lang="html">
  <div id="blog-page">

    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 col-page-title-t1">
          <h1 class="text-center title">Comunicados</h1>
        </div>

        <!-- <div class="col-12 col-form">
          <b-form @submit="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="formSearch.keywords"
                type="text"
                placeholder="Buscar artículo..."
                required
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" class="btn-search"><i class="fal fa-search"></i></b-button>
          </b-form>
        </div> -->

       <!--  <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1">
          <router-link class="box-article" to="/comunicados/1">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/blog-1.jpg)' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="box-descr">
              <h5>Título de este artículo con dos lineas máximo</h5>

              <div class="descr">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.
                </p>
              </div>
            </div>
          </router-link>
        </div> -->

        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, baInx) in news" :key="'ba-'+baInx">
          <router-link class="box-article" :to="'/comunicados/'+blog.id">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{blog.title}}</h5>

              <div class="descr" v-html="blog.body">
                
              </div>
            </div>
          </router-link>
        </div>

      </div>

    <!--   <div class="row">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },
      news:[]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
    getNews(){
    
      axios.get(tools.url("/api/blogs")).then((response)=>{
          this.news = response.data.news;
          
        }).catch((error)=>{
        });
    },

    linkGen(pageNum) {
      return {
        name: 'comuPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    }
  },
  mounted(){
      this.getNews();
  }
}
</script>
