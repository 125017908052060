<template lang="html">
  <div id="certificates-page">

    <section class="container articles-section">
      <div class="row justify-content-center">
        <div class="col-12 col-page-title-t1">
          <h1 class="text-center title">{{row.name}}</h1>
        </div>

        <div class="col-sm-6 col-lg-4 col-xl-3 col-certificate-sample-1" v-for="(doc, baInx) in row.documents" :key="'ba-'+baInx">
          <router-link class="t-400 box-article" :to="'/certificado/'+doc.digital_folio+'/'+doc.id">
            <div class="t-400 placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/certificates/certificate.png)' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="box-info">
              <h5>{{doc.certificate}}</h5>
            </div>
          </router-link>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
   		id:null,
   		row:{
   			documents:[]
   		}
    }
  },
  methods: {
    getRows(){
      	axios.get(tools.url("/api/students/"+this.id)).then((response)=>{
         	this.row = response.data;
        }).catch((error)=>{
        });
    },

  },
  mounted(){

     if(this.$route.params.students_id){
           this.id = this.$root._getURLID(this.$route.params.students_id);
          this.getRows();
      }

  }
}
</script>
