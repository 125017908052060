<template lang="html">
  <div id="about-us-page">

    <section class="about-section">
      <div class="placed-backg image-lg"></div>

      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-6 offset-lg-6 col-info">
            <p class="mb-3 mb-md-4 text-center">
              <img class="img-fluid logo" src="public/images/iso.jpg">
            </p>

            <h1>SOBRE NOSOTROS</h1>
            <p>
             SOMOS UNA ORGANIZACIÓN NO GUBERNAMENTAL PARA ABOGADOS HISPANOHABLANTES, DEDICADA A APOYAR, CONECTAR, CERTIFICAR AL PROFESIONISTA DEL SECTOR PÚBLICO Y PRIVADO Y, COMUNICAR A LA COMUNIDAD DE ABOGADOS QUE DESEEN EXPANDIR SUS LÍMITES Y PARTICIPAR DE FORMA ACTIVA EN CONGRESOS, CURSOS Y CAPACITACIONES.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="mission-vision-section mission">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-lg-9">
            <h2>Nuestra misión</h2>

            <p>
              Inspirar la conciencia del peso de la responsabilidad y justicia social en el ejercicio de la profesión de abogado, la cultura de la prevención, así como la mutua colaboración y capacitación entre pares.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="mission-vision-section vision">
      <div class="container oversized-container">
        <div class="row flex-center-xy">
          <div class="col-lg-9">
            <h2>Nuestra visión</h2>

            <p>
              Crear un ecosistema de cumplimiento y responsabilidad donde la integridad sea un compromiso y respeto a los derechos humanos, cada abogado una sensación de praxis para crear y trascender con tolerancia.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="values-section">
      <!-- <div class="image-lg"></div> -->

      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-top">
            <h2 class="title">VALORES COMPARTIDOS</h2>
          </div>

          <div class="col-sm-6 col-lg-3 col-info">
            <h2>INSTITUCIÓN</h2>
            <ul>
              <li>Trascender</li>
              <li>Integridad</li>
              <li>Reputación</li>
              <li>Tolerancia</li>
              <li>Éxito</li>
            </ul>
          </div>

          <div class="col-sm-6 col-lg-3 col-info">
            <h2>ÉTICO-SOCIALES</h2>
            <ul>
              <li>Equidad</li>
              <li>Justicia</li>
              <li>Armonía</li>
              <li>Responsabilidad Social</li>
            </ul>
          </div>

          <div class="col-sm-6 col-lg-3 col-info">
            <h2>ÉTICO-MORALES</h2>
            <ul>
              <li>Ética</li>
              <li>Lealtad</li>
              <li>Verdad</li>
              <li>Respeto</li>
              <li>Responsabilidad</li>
              <li>Honestidad</li>
              <li>Compromiso</li>
              <li>Solidaridad</li>
              <li>Respeto a los derechos humanos</li>
              <li>Inclusivos</li>
            </ul>
          </div>

          <div class="col-sm-6 col-lg-3 col-info">
            <h2>COMPETENCIA</h2>
            <ul>
              <li>Trabajo en equipo</li>
              <li>Calidad</li>
              <li>Orden</li>
              <li>Diálogo</li>
              <li>Liderazgo</li>
              <li>Prevención</li>
              <li>Vocación</li>
              <li>Cultura</li>
              <li>Comunicación</li>
              <li>Desarrollo de otros</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
